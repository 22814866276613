"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Skeleton from "./Skeleton";
const AllImageSettings = dynamic(
  () => import("@/components/AllImageSettings"),
  {
    loading: () => (
      <div className="row">
        <div className="col-md-12 mt-3">
          <Skeleton
            type={"image"}
            width={"100%"}
            height={"75px"}
            marginBottom={"15px"}
            marginTop={"15px"}
            padding={"0px"}
          />
        </div>
      </div>
    ),
  }
);
const AllImageSettingsSidebar = dynamic(
  () => import("@/components/AllImageSettings"),
  {
    loading: () => (
      <div className="row">
        <div className="col-md-12 mt-3">
          <Skeleton
            type={"image"}
            width={"100%"}
            height={"320px"}
            marginBottom={"15px"}
            padding={"0px"}
          />
        </div>
      </div>
    ),
  }
);
const AllImageSettingsSlider = dynamic(
  () => import("@/components/AllImageSettings"),
  {
    loading: () => (
      <div className="row">
        <div className="col-md-12 mt-3">
          <Skeleton
            type={"image"}
            width={"100%"}
            height={"65px"}
            marginBottom={"15px"}
            marginRight={"15px"}
            padding={"0px"}
          />
        </div>
      </div>
    ),
  }
);

const AdsImages = ({ type, url, padding }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getAdsData();
  }, []);
  const getAdsData = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_DATA_URL + url, {
        next: { revalidate: 60 },
      });
      const resData = await res.json();
      if (resData) {
        setData(resData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {data ? (
        <>
          {type == "HEADER" ? (
            <div className="main-container-fluid container-fluid">
              {data.image_url || data.image ? (
                <AllImageSettings
                  type={"AdImgCenter"}
                  adsLink={data.link}
                  image={
                    data.image_url
                      ? data.image_url
                      : process.env.REACT_APP_DATA_IMAGE_URL + data.image
                  }
                  imageCaption={data.image_caption}
                  width={data.width + "%"}
                  padding={padding}
                  height={"75px"}
                />
              ) : (
                <Skeleton
                  type={"image"}
                  width={"100%"}
                  height={"75px"}
                  marginTop={"15px"}
                  marginBottom={"15px"}
                  padding={"0px"}
                />
              )}
            </div>
          ) : type == "SIDEBAR" ? (
            <>
              {data.image_url || data.image ? (
                <AllImageSettingsSidebar
                  type={"AdImgSidebar"}
                  adsLink={data.link}
                  image={
                    data.image_url
                      ? data.image_url
                      : process.env.REACT_APP_DATA_IMAGE_URL + data.image
                  }
                  imageCaption={data.image_caption}
                  width={data.width + "%"}
                  padding={padding}
                />
              ) : (
                <Skeleton
                  type={"image"}
                  width={"100%"}
                  height={"320px"}
                  marginTop={"15px"}
                  marginBottom={"15px"}
                  padding={"0px"}
                />
              )}
            </>
          ) : type == "SLIDER" ? (
            <>
              {data.image_url || data.image ? (
                <AllImageSettingsSlider
                  type={"AdImgCenterTwo"}
                  adsLink={data.link}
                  image={
                    data.image_url
                      ? data.image_url
                      : process.env.REACT_APP_DATA_IMAGE_URL + data.image
                  }
                  imageCaption={data.image_caption}
                  width={data.width + "%"}
                  padding={padding}
                />
              ) : (
                <Skeleton
                  type={"image"}
                  width={"100%"}
                  height={"65px"}
                  marginBottom={"15px"}
                  marginRight={"15px"}
                  padding={"0px"}
                />
              )}
            </>
          ) : type == "FOOTER" ? (
            <div className="main-container-fluid container-fluid">
              {data.image_url || data.image ? (
                <AllImageSettings
                  type={"AdImgCenter"}
                  adsLink={data.link}
                  image={
                    data.image_url
                      ? data.image_url
                      : process.env.REACT_APP_DATA_IMAGE_URL + data.image
                  }
                  imageCaption={data.image_caption}
                  width={data.width + "%"}
                  padding={padding}
                  height={"75px"}
                />
              ) : (
                <Skeleton
                  type={"image"}
                  width={"100%"}
                  height={"75px"}
                  marginTop={"15px"}
                  marginBottom={"15px"}
                  padding={"0px"}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AdsImages;

import Styles from "@/styles/CardSkeleton.module.css";
import StylesIcon from "@/styles/Icons.module.css";
export default function Skeleton({
  type,
  width,
  height,
  paddingRight,
  marginBottom,
  marginRight,
  marginTop,
  padding,
  title,
}) {
  return type == "grid" ? (
    <div
      className={`${Styles.card} ${Styles.loading}`}
      style={{ width: width, height: height }}
    >
      <div className={`${Styles.image}`}></div>
      <div className={`${Styles.content}`}>
        <h4></h4>
        <div className={`${Styles.description}`}></div>
        <p></p>
      </div>
    </div>
  ) : type == "image" ? (
    <div
      className={`${Styles.card} ${Styles.loading}`}
      style={{
        width: width,
        height: height,
        paddingRight: paddingRight,
        border: "none",
        marginBottom: marginBottom,
        marginTop: marginTop,
        marginRight: marginRight,
        padding: padding,
      }}
    >
      <div className={`${Styles.image}`} style={{ height: height }}></div>
    </div>
  ) : type == "small-image" ? (
    <div
      className={`${Styles.card} ${Styles.loading} page-image-small`}
      style={{
        height: height,
        paddingRight: paddingRight,
        border: "none",
        marginBottom: marginBottom,
        marginRight: marginRight,
      }}
    >
      <div className={`${Styles.image}`} style={{ height: height }}></div>
    </div>
  ) : type == "imageTwo" ? (
    <div
      className={`${Styles.card} ${Styles.loading}`}
      style={{
        width: width,
        height: height,
        marginRight: "15px",
        marginBottom: "15px",
      }}
    >
      <div className={`${Styles.image}`} style={{ height: height }}></div>
    </div>
  ) : type == "small-grid" ? (
    <div
      className={`${Styles.card} ${Styles.loading}`}
      style={{ width: width, height: height }}
    >
      <div
        className={`${Styles.image}`}
        style={{ width: "35%", float: "left", height: "95px" }}
      ></div>
      <div
        className={`${Styles.content}`}
        style={{ width: "65%", float: "left", padding: "0px 10px" }}
      >
        <h4></h4>
        <p style={{ minHeight: "1rem" }}></p>
        <div
          className={`${Styles.description}`}
          style={{ minHeight: "1rem" }}
        ></div>
      </div>
    </div>
  ) : type == "line" ? (
    <div
      className={`${Styles.card} ${Styles.loading}`}
      style={{
        width: width,
        height: height,
        border: "1px solid #fff",
        margin: "0px",
      }}
    >
      <div className={`${Styles.content}`} style={{ padding: "0px" }}>
        <p style={{ minHeight: "1rem", margin: "0px" }}></p>
      </div>
    </div>
  ) : type == "single" ? (
    <div
      className={`${Styles.card} ${Styles.loading}`}
      style={{
        width: width,
        height: "auto",
        border: "1px solid #fff",
        margin: "0px",
      }}
    >
      <div className={`${Styles.content}`} style={{ padding: "0px" }}>
        <h4></h4>
        <div
          className={`${Styles.description}`}
          style={{ marginBottom: "7px" }}
        ></div>
        <div className={`${Styles.image}`} style={{ height: height }}></div>
        <div
          className={`${Styles.description}`}
          style={{ margin: "10px 0px" }}
        ></div>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
        <p style={{ minHeight: "1rem", margin: "5px 0px" }}></p>
      </div>
    </div>
  ) : type == "sidebarTop" ? (
    <div className="sidebar order-lg-1 widget widget-headlines">
      <h2 className="widget-title">{title}</h2>

      {[...Array(5)].map((e, i) => (
        <div
          key={i}
          className={`${Styles.card} ${Styles.loading}`}
          style={{ width: width, height: height }}
        >
          <div
            className={`${Styles.image}`}
            style={{ width: "35%", float: "left", height: "95px" }}
          ></div>
          <div
            className={`${Styles.content}`}
            style={{ width: "65%", float: "left", padding: "0px 10px" }}
          >
            <h4></h4>
            <p style={{ minHeight: "1rem" }}></p>
            <div
              className={`${Styles.description}`}
              style={{ minHeight: "1rem" }}
            ></div>
          </div>
        </div>
      ))}
      <div style={{ width: "100%" }}>
        <div className="text-center custom-slider-btns">
          <button
            type="button"
            aria-label="Prev Photo"
            title="Prev Photo"
            className="custom-slider-left-btn"
          >
            <i
              className={`${StylesIcon.chevron__left}`}
              style={{ color: "#fff" }}
            ></i>
          </button>
          <button
            type="button"
            aria-label="Next News"
            title="Next News"
            className="custom-slider-right-btn"
          >
            <i
              className={`${StylesIcon.chevron__right}`}
              style={{ color: "#fff" }}
            ></i>
          </button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
